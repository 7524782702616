@import url(http://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700);
@import url(http://fonts.googleapis.com/css?family=Manrope:100,200,300,400,500,600,700);

@tailwind base;
@tailwind components;
@tailwind utilities;

.admin_panel_sidenav_width {
  width: 100px;
}
.admin_panel_main_width {
  width: calc(100vw - 100px);
}

.dropdown-button:focus + .dropdown-menu {
  display: block;
}

.thumbnail {
  height: 520px;
  width: 390px;
  color: #1b1b1be0;
}
.hide-scroll {
  scrollbar-width: none;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.h-one-third {
  height: 80vh;
}
.min-width-half {
  min-width: 50%;
}

.chat-height {
  height: 60vh;
}

body {
  font-family: "Poppins";
}

.h-max {
  height: 500px;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  position: relative;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #7e8bc6;
}
input[type="range"]::-webkit-slider-runnable-track {
  background: #acceed;
  height: 2px;
}
input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
  -webkit-appearance: none;
  background-color: #4993d8;
  margin-top: -3.5px;
  z-index: 99;
  position: relative;
  transition: 0.5s all ease;
}

.maximum_width {
  width: 150px;
}

.table .cell {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table > tbody > tr > td {
  vertical-align: middle;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  border-top: 1px solid #ececec;
  font-size: 16px;
  color: #1c1c1c;
  white-space: nowrap;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.cell-ellipsis {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input[type="text"]:disabled {
  background-color: transparent;
}

.aspect_video {
  aspect-ratio: 9/16;
}
.tagline-container {
  width: 100%;
  min-width: 50%;
  max-width: 1200px;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  background-image: url("./images/loginauth.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 1024px) {
  .tagline-container {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .tagline-container {
    min-width: 30%;
  }
}

@media only screen and (max-width: 1024px) {
  .login-container {
    height: 100vh;
    width: 100%;
    max-width: 608px;
    margin: 0 auto;
    padding: 0 16px;
  }
}

.login-container {
  max-width: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 450px) {
  .login-box {
    width: 100% !important;
    margin: 0px !important;
  }

  .copyright {
    left: 20px !important;
  }
}
@media (min-width: 451px) and (max-width: 1024px) {
  .login-box {
    width: 100% !important;
    margin: 20px !important;
  }
  .copyright {
    left: 40px !important;
  }
}
@media only screen and (max-width: 1440px) {
  .login-box {
    margin: 0 80px;
  }
}

.login-box {
  width: 480px;
  margin: 0 160px 0 80px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.copyright {
  position: absolute;
  left: 80px;
  bottom: 40px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: grey;
}
.logo {
  position: absolute;
  top: 40px;
  left: 40px;
}
.tagline {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

#loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s forwards infinite;
  -webkit-animation: spin 1s forwards infinite;
}

.popup {
  animation: popup 100ms forwards;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes popup {
  from {
    scale: 0.8;
    opacity: 0.5;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
input:-webkit-autofill::first-line {
  font-size: 14px !important;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
}

.rotate-full {
  transform: rotateX(180deg);
}

.h-almost {
  height: 90vh;
}
